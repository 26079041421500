import { ReactComponent as LogoImg } from 'src/assets/logo.svg';
import * as Styled from './Logo.styled';

export function Logo() {
  return (
    <Styled.Logo>
      <LogoImg fill="black" viewBox="0 0 120 24" width="100%" height="auto" />
    </Styled.Logo>
  );
}
