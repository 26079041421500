import { ThemeProvider, Typography } from '@mui/material';
import { Outlet, useSearchParams, useNavigate, useMatch } from 'react-router-dom';
import { Layout, LayoutContainer, LayoutFooter, LayoutBody } from '@components/Layout';
import { muiTheme } from './theme';
import { CircularProgress } from '@components/ui';
import { Logos } from '@components/Logos';
import { useDeviceDetector, DeviceProvider } from './context/device';
import { ApiProvider, DEFAULT_API_VALUES } from './context/api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RETURN_QUERY_KEY, __DEV__ } from './settings';
import { FetchingContainer } from './App.styled';
import { MessagingProvider } from './context/messaging';
import { MessagingService } from './services/messaging';
import { LogTransport, PostMessageTransport, UrlTransport } from './services/messaging/transport';
import { urlService } from './services/urlService';
import { RoutePaths } from './routes';
import { LoggedIn } from './components/LoggedIn';
import { ERRORS } from './constants';
import { Logo } from './components/Logo';

export default function App() {
  const [searchParams] = useSearchParams();
  const [initialAuthed, setInitialAuth] = useState<boolean>(false);
  const [authed, setAuthed] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(true);
  const device = useDeviceDetector();
  const navigate = useNavigate();
  const isMainPage = useMatch('/');
  const returnUrl = useRef(
    decodeURIComponent(searchParams.get(RETURN_QUERY_KEY) || localStorage.getItem(RETURN_QUERY_KEY) || '')
  );

  const setIsAuth = useCallback((auth: boolean) => {
    if (auth && !returnUrl.current) {
      setInitialAuth(true);
    } else {
      setAuthed(auth);
    }
  }, []);

  // Messaging
  const messagingService = useMemo(() => {
    const messaging = new MessagingService();

    if (returnUrl.current) {
      if (urlService.checkIsValidURL(returnUrl.current)) {
        localStorage.setItem(RETURN_QUERY_KEY, returnUrl.current);
        messaging.addTransport(new UrlTransport(returnUrl.current));
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Url "${returnUrl.current}" does not exist in white list`);
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(`You don't have a "${RETURN_QUERY_KEY}" query param`);
    }

    if (window.opener) {
      messaging.addTransport(new PostMessageTransport());
    }

    if (__DEV__) {
      messaging.addTransport(new LogTransport());
    }

    messaging.init();
    return messaging;
  }, []);

  // API
  const { accountsClient, connectClient } = DEFAULT_API_VALUES;

  const handleLogout = () => {
    connectClient
      .logout()
      .then(() => {
        setInitialAuth(false);
        setAuthed(false);
        navigate(RoutePaths.SIGN_IN, { replace: true });
        messagingService.logoutSuccess();
      })
      .catch((e) => {
        messagingService.logoutError(e.message);
      });
  };

  // Check Auth
  useEffect(() => {
    setFetching(true);
    accountsClient
      .getAccount()
      .then(() => {
        setFetching(false);
        setInitialAuth(true);
        setAuthed(false);
        messagingService.alreadyAuthed();
      })
      .catch((e) => {
        setFetching(false);
        if (e.message === ERRORS.PASSWORD_INCORRECT) {
          setInitialAuth(false);
          setAuthed(false);
          if (isMainPage) {
            navigate(RoutePaths.SIGN_IN, { replace: true });
          }
        }
      });
  }, []);

  const isLoggedIn = !isFetching && initialAuthed;
  const isNotLoggedIn = !isFetching && !initialAuthed && !authed;

  return (
    <DeviceProvider value={device}>
      <ApiProvider value={DEFAULT_API_VALUES}>
        <MessagingProvider value={messagingService}>
          <ThemeProvider theme={muiTheme}>
            <Layout isMobile={device.isMobile}>
              <LayoutContainer isMobile={device.isMobile}>
                <LayoutBody isMobile={device.isMobile}>
                  <Logo />

                  {isFetching && (
                    <FetchingContainer>
                      <CircularProgress />
                    </FetchingContainer>
                  )}

                  {isLoggedIn && <LoggedIn onLogout={handleLogout} />}

                  {authed && (
                    <Typography sx={{ margin: '25px 0' }} variant="body2">
                      You have successfully logged in. Redirecting...
                    </Typography>
                  )}

                  {isNotLoggedIn && <Outlet context={{ isAuth: authed, setIsAuth }} />}
                </LayoutBody>

                <LayoutFooter isMobile={device.isMobile}>
                  <Logos />
                </LayoutFooter>
              </LayoutContainer>
            </Layout>
          </ThemeProvider>
        </MessagingProvider>
      </ApiProvider>
    </DeviceProvider>
  );
}
