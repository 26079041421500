// Environment
export const __DEV__ = process.env.NODE_ENV === 'development';
export const __PRODUCTION__ = process.env.NODE_ENV === 'production';
export const __TEST__ = process.env.NODE_ENV === 'test';
const PROVIDE_LOCALHOST = process.env.REACT_APP_PROVIDE_LOCALHOST === 'true';

// Redirect rules
export const DEFAULT_REDIRECT_URL = 'https://miniroyale.io/';
export const REDIRECT_DOMAINS_WHITE_LIST = [
  'faraway.gg',
  'miniroyale.io',
  '*.faraway.gg',
  '*.miniroyale.io',
  'faraway.com',
  '*.faraway.com',
];

if (__DEV__ || PROVIDE_LOCALHOST) {
  REDIRECT_DOMAINS_WHITE_LIST.push('localhost', '127.0.0.1');
}

// API endpoint
export const API_SERVER = process.env.REACT_APP_API_SERVER || 'https://dev1-server.faraway.com';

// Screen settings
export const SCREEN_MOBILE_BREAKPOINT = 767;
export const SCREEN_TABLET_BREAKPOINT = 1279;

// Other constants
export const PRIVACY_POLICY_URL = 'https://faraway.com/privacy';
export const TERMS_URL = 'https://faraway.com/terms';

export const RETURN_QUERY_KEY = 'redirect_uri';
