import { RETURN_QUERY_KEY } from 'src/settings';
import { MessagingTransport } from './typings';

export class UrlTransport extends MessagingTransport {
  constructor(private urlString: string) {
    super();
  }

  authSuccess() {
    localStorage.removeItem(RETURN_QUERY_KEY);
    return window.location.replace(this.urlString);
  }
}
